
var vmProject = {}

// 交易市场页数
var tradingPage = 1
// 交易市场每页数量
var tradingSize = 10

$(function () {
  // 获取日期方法
  function getStartTime(str, format) {
    return moment(new Date(+str)).format(format)
  }
  //  获取服务列表 zlk
  // getBusinessServiceList()
  // 获取产品导航列表 zlk
  getBusinessNavList()
  // green
  getBannerList()
  getTopServer()
  getTradingList(1, tradingPage, tradingSize)

  //视频控制开关
  $('.close').click(function () {
    $('.mask1').removeClass('active')
    $('.two').removeClass('active')
    $('.mask3').removeClass('active')
    $('.video1')[0].pause()
    $('.video2')[0].pause()
    $('.video3')[0].pause()
  })
  $('.total li')
    .eq(0)
    .click(function () {
      $('.mask1').addClass('active')
      $('.video1')[0].play()
    })
  $('.total li')
    .eq(1)
    .click(function () {
      $('.two').addClass('active')
      $('.video2')[0].play()
    })
  $('.total li')
    .eq(2)
    .click(function () {
      $('.mask3').addClass('active')
      $('.video3')[0].play()
    })

  //划过入口显示
  $('.videoer').hover(
    function () {
      $('.total').addClass('active')
    },
    function () {
      $('.total').removeClass('active')
    }
  )
  $('.total').hover(
    function () {
      $('.total').addClass('active')
    },
    function () {
      $('.total').removeClass('active')
    }
  )

  //获取产品导航列表zlk
  function getBusinessNavList() {
    function rendering(res) {
      res = JSON.parse(res)
      let serviceNavList = res.content
      for (let i = 0; i < serviceNavList.length; i++) {
        let navServiceList = serviceNavList[i]
        if (navServiceList.commodityName === '其他服务') {
          $('#businessNavList').append(
            "<li class='otherPart'><div class='clearfix view1'><h4 title=" +
            navServiceList.commodityName +
            "  class='pull-left'><a target='_blank' href=" +
            navServiceList.jumpUrl +
            " class='pei-go' >" +
            navServiceList.commodityName +
            '</a></h4>' +
            "<img class = 'pull-left' src='/images/lodding.png'  data-src='/images/index2iconmore.png' alt = '' /><p title='如未找到业务请咨询客服' class='pull-right cumService-btn'><a target='_blank' class='pei-go'  href=" +
            navServiceList.jumpUrl +
            '>' +
            '如未找到业务请咨询客服' +
            '</a></p></div><div class="view2" id="businessList' +
            i +
            '"></div></li>'
          )
        } else {
          $('#businessNavList').append(
            "<li><div class='clearfix view1'><h4 title=" +
            navServiceList.commodityName +
            " class='pull-left'><a target='_blank' href=" +
            navServiceList.jumpUrl +
            " class='random-color-" + (i + 1) + " pei-go' >" +
            navServiceList.commodityName +
            '</a></h4>' +
            "<img class='pull-right' src='/images/lodding.png'  data-src='/images/index2iconmore.png' alt = " +
            navServiceList.commodityName +
            ' /></div><p title=' +
            navServiceList.slogan +
            "><a target='_blank' style='font-size: 12px;color: #999999;' href=" +
            navServiceList.jumpUrl +
            " class='pei-go' >" +
            (navServiceList.slogan === 'null' ? '' : navServiceList.slogan) +
            '</a></p><div class="view2" id="businessList' +
            i +
            '"></div></li>'
          )
        }
        for (let j = 0; j < navServiceList.childList.length; j++) {
          let serviceList = navServiceList.childList[j]
          if (serviceList.isHot === 1) {

            $('#businessList' + i).append(
              "<a target='_blank' class='pei-go' href=" +
              serviceList.jumpUrl +
              ' title=' +
              serviceList.commodityName +
              '  val=' +
              serviceList.jumpUrl +
              "><p class='listname pull-left'>" +
              serviceList.commodityName +
              "</p><img class='pull-right' src='/images/lodding.png' alt=" +
              serviceList.commodityName +
              " data-src='/images/hot.png'" +
              "width='10' style='margin-bottom: 5px' /></a>"
            )
          } else if (serviceList.isHot === 0) {
            $('#businessList' + i).append(
              "<a target='_blank' class='pei-go' href=" +
              serviceList.jumpUrl +
              ' title=' +
              serviceList.commodityName +
              "><p class='listname pull-left'>" +
              serviceList.commodityName +
              '</p></a>'
            )
          }
        }
      }
    }
    // console.log('getServiceNavListDate123', sessionStorage.getItem('getServiceNavListDate'))
    if (sessionStorage.getItem('getServiceNavListDate')) {
      rendering(sessionStorage.getItem('getServiceNavListDate'))
    } else {
      $.ajax({
        type: 'POST',
        url: '/getServiceNavList',
        data: {},
        dataType: 'json',
        success: function (res) {
          if (res.code === '0') {
            // console.log(res);
            res = JSON.stringify(res)
            sessionStorage.setItem('getServiceNavListDate', res)
            rendering(res)
          } else {
            toast(res.msg)
          }
        }
      })
    }
  }
  // 全部业务点击查看更多
  //获取可视窗口gaodu
  var windowHeight = $(window).height()
  if ($.cookie('drawType') != 1) {
    setTimeout(function () {
      $('.drawEnterGray')
        .height(windowHeight)
        .css('display', 'block')
        .find('.drawContent')
        .fadeIn()
    }, 30)
    var expiresDate = new Date()
    expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000)
    $.cookie('drawType', '1', {
      path: '/',
      expires: expiresDate,
      // domain: document.domain.replace(/^[^\.]+\./, ''),
      secure: false
    })
  }

  $('.close').on('click', function () {
    $('.drawEnterGray').fadeOut()
  })
  $('.more1').click(function (event) {
    var h = $('#businessNavList').height() + 66
    // console.log(h)
    $('.more1').hide()
    $('.more2').show()
    $('.business-view.pull-left').animate({
      height: h + 'px'
    })
    // $('.business-view.pull-left').addClass('business-view-show')
    event.stopPropagation()
  })
  // 6.19

  $('.business-left').mouseleave(function (event) {
    $('.more1').show()
    $('.more2').hide()
    $('.business-view.pull-left').animate({
      height: '266px'
    })
    $('.business-view.pull-left').removeClass('business-view-show')
    event.stopPropagation()
  })
  $('.more2 .img').click(function (event) {
    $('.more1').show()
    $('.more2').hide()
    $('.business-view.pull-left').animate({
      height: '266px'
    })
    $('.business-view.pull-left').removeClass('business-view-show')
    event.stopPropagation()
  })
  $(document).click(function (event) {
    $('.business-view.pull-left').removeClass('business-view-show')
    $('.business-view.pull-left').animate({
      height: '266px'
    })
    $('.more1').show()
    $('.more2').hide()
    event.stopPropagation()
    $('.business-view.pull-left').removeClass('business-view-show')
  })

  $('.tab ul li:nth-child(1)').click(function () {
    $(this).addClass('active')
    $(this)
      .siblings()
      .removeClass('active')
    console.log(11, $(this))

    $('#handleLink').attr('href', '/project-list?projectType=' + 3 + '&index=0')
    vmProject.getProjectList(3)
  })
  $('.tab ul li:nth-child(2)').click(function () {
    $(this).addClass('active')
    $(this)
      .siblings()
      .removeClass('active')

    $('#handleLink').attr('href', '/project-list?projectType=' + 2 + '&index=1')
    vmProject.getProjectList(2)
  })
  $('.tab ul li:nth-child(3)').click(function () {
    $(this).addClass('active')
    $(this)
      .siblings()
      .removeClass('active')

    $('#handleLink').attr('href', '/project-list?projectType=' + 1 + '&index=2')
    vmProject.getProjectList(1)
  })
  $('.tab ul li:nth-child(4)').click(function () {
    $(this).addClass('active')
    $(this)
      .siblings()
      .removeClass('active')

    $('#handleLink').attr('href', '/project-list?projectType=' + 4 + '&index=3')
    vmProject.getProjectList(4)
  })
  // $('#handleLink').click(function() {

  // })
  $('.search a').click(function () {
    var inputVal = $('.search input').val()
    var keyword = '/search?keyword=' + inputVal
    $(this).attr('href', keyword)
  })

  $(window).scroll(function (event) {
    if ($(this).scrollTop() > 100) {
      $('.topicon').fadeIn()
    } else {
      $('.topicon').fadeOut()
    }
    var topiconTop = $(window).height() - 280 + $(window).scrollTop() + 'px'
    var scrollTop = $(this).scrollTop()
    var scrollHeight = $(document).height()
    var windowHeight = $(this).height()
  })

  //   返回顶部
  $('.topicon,.sideBarBottom').click(function () {
    $('html , body').animate(
      {
        scrollTop: 0
      },
      'slow'
    )
  })
  // 全部业务逻辑
  var val1 = $('.accordion-box li a').attr('val1')

  $('.accordion-box li a').click(function () {
    var val1 = $(this).attr('val1')
    var val2 = $(this).attr('val2')
    window.localStorage.setItem('val1', val1)
    window.localStorage.setItem('val2', val2)
  })

  $('.indexAllBusiness li .view2 a').click(function () {
    var val1 = $(this).attr('val1')
    var val2 = $(this).attr('val2')
    window.localStorage.setItem('val1', val1)
    window.localStorage.setItem('val2', val2)
  })
  $('.indexAllBusiness li .view1  a').click(function () {
    var val1 = $(this).attr('val1')
    var val2 = $(this).attr('val2')
    window.localStorage.setItem('val1', val1)
    window.localStorage.setItem('val2', val2)
  })
  // 招标中心列表
  vmProject.getProjectList = function (projectType) {
    $('.project-center-view2-left-main ul').empty()
    $('.project-center-new-botom-right-content').empty()
    $('.project-center-view2-left-main .loader').fadeIn()
    $.post(
      '/searchProjectIndex',
      {
        area: $.cookie('areaId'), //接口新增参数zlk
        businessType: 1,
        pageNo: 1,
        firstNo: 'CA047',
        projectType: projectType,
        size: 9,
      },
      function (result) {

        var code = result.code
        if (code && code === '0') {
          var data = result.content.data
          $('.project-center-view2-left-main .loader').fadeOut()
          if (data.length === 0) {
            var liTag = '<li class="clearfix noList-data">'
            liTag +=
              '<a href="/transaction-quick?productNo=&amp;productName=" target="_blank">'
            liTag +=
              '<img class="zhanweiimg" src="/images/lodding.png"  data-src="/images/nodata-list.png"/></a>'
            liTag += '</li>'
            $('.project-center-view2-left-main ul').append(liTag)

            return
          }
          $.each(data, function (index, item) {
            var orderStatus = ''

            if (item.orderStatus >= 20 && item.orderStatus <= 40) {
              orderStatus = '招标中'
            } else if (item.orderStatus > 40 && item.orderStatus <= 90) {
              orderStatus = '正在服务'
            } else if (item.orderStatus > 90) {
              orderStatus = '完成招标'
            }

            function todate3(beginTime) {
              var hour, min
              var d = +Math.floor(beginTime / 1000 / 60 / 60 / 24)
              hour = Math.floor((beginTime / 60 / 60 / 1000) % 24)
              min = Math.ceil((beginTime / 60 / 1000) % 60)
              hour < 10 ? (hour = '0' + hour) : (hour = hour + '')
              min < 10 ? (min = '0' + min) : (min = min + '')
              if (d > 0) {
                return d + '天' + hour + '小时' + min + '分'
              } else {
                return hour + '小时' + min + '分'
              }
            }

            if (projectType == 2) {
              window.clearInterval(timers['timer' + index])
              getCountDown(item.orderBeginTime, index)

              function todate2(beginTime, times) {
                var hour, min
                hour = Math.floor(beginTime / 60)
                min = beginTime % 60
                return hour + '时' + min + '分'
              }
              var liTag = `
              <a href="/project-center-details/${item.orderNo}" target="_blank" class="fuwuzhong-item">
                <div class="fuwuzhong-item-top">
                  <div class="tag-1">
                    工商财税</div>
                  <div class="tag-2">
                    多</div>
                  <div class="project-name">${item.orderName ? item.orderName : '-'}</div>
                  <div class="fuwuzhong-item-top-content-margin">发布者:${item.enterpriseName ? item.enterpriseName : '-'}</div>
                  <span class="localtion-icon"></span>
                  <div class="fuwuzhong-item-top-content">${item.dealAddress ? item.dealAddress : '-'}</div>
                </div>
                <div class="fuwuzhong-item-divider"></div>
                <div class="fuwuzhong-item-content">
                  <div class="jingzhengzhe-count">${item.bidderCount ? item.bidderCount + '人' : '0人'}</div>
                  <div class="jingzhengzhe">竞标者</div>
                  <div style="display: flex;flex-direction: column;align-items: center;">
                    <div class="fuwuzhong-item-content">
                      <div class="text">${item.realBeginTimeOne == 0 ? '预计' : '用时'}</div>
                      <div class="text-number">>${item.realBeginTimeOne == 0 ? todate2(item.estimatedTimeOne) : todate2(item.realBeginTimeOne)}</div>
                    </div>
                    <div style="font-size: 12px; color: #999999; margin-top: 8px; ">竞标满${item.biddingUserOne}人用时</div>
                  </div>
                  <div style="display: flex;flex-direction: column;align-items: center;margin-left: 50px;">
                    <div class="fuwuzhong-item-content">
                      <div class="text">${item.realBeginTimeTwo == 0 ? '预计' : '用时'}</div>
                      <div class="text-number">${item.realBeginTimeTwo == 0 ? todate2(item.estimatedTimeOne) : todate2(item.realBeginTimeTwo)}</div>
                    </div>
                    <div style="font-size: 12px; color: #999999; margin-top: 8px; ">竞标满${item.biddingUserTwo}人用时</div>
                  </div>
                  <div class="fuwuzhong-item-content" style="margin-left: 50px;">
                    <div class="count-dwon count-bg ${'dataValHour' + index}">00</div>
                    <div class="count-dwon">时</div>
                    <div class="count-dwon count-bg ${'dataValMin' + index}">00</div>
                    <div class="count-dwon">分</div>
                    <div class="count-dwon count-bg ${'dataValSec' + index}">00</div>
                    <div class="count-dwon">秒</div>
                  </div>
                  <div class="text-margin">浏览量：</div>
                  <div class="text-number">${item.followCount ? item.followCount : '0'}</div>
                  <div class="text">人浏览</div>
                </div>
              </a>
              `
              var liTagold = `
                <li class="clearfix" onclick="javascript:window.open('/project-center-details/${item.orderNo
                }');">
                    <div class="projectTop">
                    <div class="nameType" style="width:272px;">
                    <h6 title="${item.orderName ? item.orderName : '-'
                }" style="${item.orderKind === 2 ? 'margin-right:5px;' : 'max-width:140px;'
                }">${item.orderName ? item.orderName : '-'}</h6>
                     ${item.orderKind === 2
                  ? `<span class="imgTypeBox"><img class="imgType" src='/images/lodding.png' data-src="/images/moreProduct.png" /></span>`
                  : ''
                }
                    </div>

                   <p class="enterpriseName">发布者:${item.enterpriseName ? item.enterpriseName : '-'
                }</p>
                  <div class="projectAddress">
                    <span class="posImg"></span>
                    <p title="${item.dealAddress ? item.dealAddress : '-'}">${item.dealAddress ? item.dealAddress : '-'
                }</p>
                  </div>
              </div>
              <div class="projectDown">
                  <dl>  
                    <dd>
                        <p class="aas countTime  ${'dataValA' + index}"></p>
                        <h6>招标时长</h6>
                    </dd>
                    <dd>
                        <p>${item.realBeginTimeOne == 0 ? '预计' : '用时'
                }<span>${item.realBeginTimeOne == 0
                  ? todate2(item.estimatedTimeOne)
                  : todate2(item.realBeginTimeOne)
                }</span></p>
                        <h6>竞标者满${item.biddingUserOne}人</h6>
                    </dd>
                    <dd>
                        <p>${item.realBeginTimeTwo == 0 ? '预计' : '用时'
                }<span>${item.realBeginTimeTwo == 0
                  ? todate2(item.estimatedTimeTwo, 1)
                  : todate2(item.realBeginTimeTwo, 1)
                }</span></p>
                        <h6>竞标者满${item.biddingUserTwo}人</h6>
                    </dd>
                    <dd>
                        <p>${item.followCount ? item.followCount : '0'}人</p>
                        <h6>关注</h6>
                    </dd>
                    <dd>
                        <p>${item.bidderCount ? item.bidderCount : '0'}人</p>
                        <h6>竞标者</h6>
                    </dd>
                  </dl>
              </div>
          </li> `

              $('.project-center-view2-left-main ul').append(liTagold)
              $('.project-center-new-botom-right-content').append(liTag)
            } else if (projectType == 3) {
              var liTag = `
              <a target="_blank" href="/project-center-details/${item.orderNo}" class="fuwuzhong-item">
                <div class="fuwuzhong-item-top">
                  <div class="tag-1">
                    工商财税</div>
                  <div class="tag-2">
                    多</div>
                  <div class="project-name">${item.orderName ? item.orderName : '保密'}</div>
                  <div class="fuwuzhong-item-top-content-margin">招标用时:${todate3(
                item.serverStartTime
                  ? item.serverStartTime - item.orderBeginTime
                  : new Date().getTime() - item.orderBeginTime
              )}</div>
                  <div class="fuwuzhong-item-top-content-margin">发布者:${item.enterpriseName ? item.enterpriseName : '保密'}</div>
                  <span class="localtion-icon"></span>
                  <div class="fuwuzhong-item-top-content">${item.dealAddress ? item.dealAddress : '保密'}</div>
                </div>
                <div class="fuwuzhong-item-divider"></div>
                <div class="fuwuzhong-item-content">
                  <div class="zhongbiaoren">中标人：${item.serverName ? item.serverName : '保密'}</div>
                  <div class="zhongbiaoren" style="margin-left: 24px;">承诺时长：${item.completeDays ? item.completeDays + '天' : '1天'}</div>
                </div>
                <div class="fuwuzhong-item-content">
                  <div class="jingzhengzhe-count">${item.bidderCount ? item.bidderCount + '人' : '0人'}</div>
                  <div class="jingzhengzhe">竞标者</div>
                  <div class="text-margin">中标价格：</div>
                  <div class="text-number">${item.price ? item.price.toFixed(2) : '0.00'}元</div>
                  <div class="text-margin">低于市场均价：</div>
                  <div class="text-number">${item.salePrice ? item.salePrice.toFixed(2) : (item.price * 0.2).toFixed(2)}元</div>
                  <div class="text-margin">浏览量：</div>
                  <div class="text-number">${item.followCount ? item.followCount : '0'}</div>
                  <div class="text">人浏览</div>
                </div>
              </a>
              `

              var liTagold = `<li class="clearfix">
                            <a target="_blank" href="/project-center-details/${item.orderNo
                }">
                            <div class="projectTop">
                            <div class="nameType"  style="width:160px;">
                            <h6 title="${item.orderName ? item.orderName : '保密'
                }" style="${item.orderKind === 2 ? 'margin-right:5px;' : 'max-width:140px;'
                }">${item.orderName ? item.orderName : '保密'}</h6>
                ${item.orderKind === 2
                  ? `<span class="imgTypeBox"><img class="imgType" src='/images/lodding.png'  data-src="/images/moreProduct.png" /></span>`
                  : ''
                }
                </div>
                                <p class="enterpriseName">发布者:${item.enterpriseName
                  ? item.enterpriseName
                  : '保密'
                }</p>
                                <div class="projectAddress">
                                <span class="posImg"></span>
                                    <p title="${item.dealAddress ? item.dealAddress : '-'
                }">${item.dealAddress ? item.dealAddress : '保密'
                }<p>
                                </div>
                            </div>
                            <div class="projectDown">
                                <dl>
                                    <dd>
                                        <p class="big orange">${item.price
                  ? '<span>￥</span>' +
                  item.price.toFixed(2)
                  : '<span>￥</span>0.00'
                }</p>
                                        <h6>中标价格</h6>
                                    </dd>
                                    <dd> 
                                        <p class="big">${item.salePrice
                  ? '<span>￥</span>' +
                  item.salePrice.toFixed(2)
                  : '<span>￥</span>' +
                  (item.price * 0.2).toFixed(2)
                }</p>
                <h6>低于市场均价</h6>
              </dd>
              <dd>
                  <p>${item.bidderCount ? item.bidderCount + '人' : '0人'}</p>
                  <h6>竞标者</h6>
              </dd>
              <dd>
              <p>${todate3(
                  item.serverStartTime
                    ? item.serverStartTime - item.orderBeginTime
                    : new Date().getTime() - item.orderBeginTime
                )}</p>
                  <h6>招标用时</h6>
              </dd>
              <dd>
                  <p>${item.completeDays ? item.completeDays + '天' : '1天'}</p>
                  <h6>承诺时长</h6>
              </dd>
          </dl>
      </div>
      </a>
  </li>`

              $('.project-center-view2-left-main ul').append(liTagold)
              $('.project-center-new-botom-right-content').append(liTag)
            } else if (projectType == 1) {
              let time = new Date().getTime()
              item.acceptanceTime = item.acceptanceTime
                ? item.acceptanceTime
                : time
              var liTag = `
                <a href="/project-center-details/${item.orderNo}" target="_blank" class="fuwuzhong-item">
                  <div class="fuwuzhong-item-top">
                    <div class="tag-1">
                      工商财税</div>
                    <div class="tag-2">
                      多</div>
                    <div class="project-name">${item.orderName ? item.orderName : '-'}</div>
                    <div class="fuwuzhong-item-top-content-margin">发布者:${item.enterpriseName ? item.enterpriseName : '--'}</div>
                    <span class="localtion-icon"></span>
                    <div class="fuwuzhong-item-top-content">${item.dealAddress ? item.dealAddress : '--'}</div>
                  </div>
                  <div class="fuwuzhong-item-divider"></div>
                  <div class="fuwuzhong-item-content">
                    <div class="zhongbiaoren">中标人：刘**</div>
                    <div class="zhongbiaoren" style="margin-left: 24px;">承诺时长：${item.completeDays ? item.completeDays + '天' : '1天'}</div>
                  </div>
                  <div class="fuwuzhong-item-content">
                    <div class="jingzhengzhe-count">30人</div>
                    <div class="jingzhengzhe">竞标者</div>
                    <div style="display: flex;flex-direction: column;align-items: center;">
                      <div class="fuwuzhong-item-content">
                        <div class="text-number">${todate3(item.serverEndTime - item.serverStartTime)}</div>
                      </div>
                      <div style="font-size: 12px; color: #999999; margin-top: 8px; ">完成时间</div>
                    </div>
                    <div style="display: flex;flex-direction: column;align-items: center; margin-left: 25px;">
                      <div class="fuwuzhong-item-content">
                        <div class="text-number">${item.price ? formatNumber(item.price, 2) : '0.00'}元</div>
                      </div>
                      <div style="font-size: 12px; color: #999999; margin-top: 8px; ">完成价格</div>
                    </div>
                    <div style="display: flex;flex-direction: column;align-items: center; margin-left: 25px;">
                      <div class="fuwuzhong-item-content">
                        <div class="text-number" style="color: #474747;">${item.salePrice ? formatNumber(item.salePrice, 2) : '0.00'}元</div>
                      </div>
                      <div style="font-size: 12px; color: #999999; margin-top: 8px; ">低于市场价</div>
                    </div>
                    <div style="display: flex;flex-direction: column;align-items: center; margin-left: 25px;">
                      <div class="fuwuzhong-item-content" style="height: 16px;">`
              // <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
              // <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
              // <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
              // <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
              // <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
              for (var i = 0; i < 5; i++) {
                if (item.score / 2 >= i + 1) {
                  liTag += `<img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />`
                } else if (item.score % 2 != 0 && item.score / 2 > i) {
                  liTag += `<img src="/images/newchicoStart2.png" style="width: 10px;height: 10px;" />`
                } else {
                  liTag += `<img src="/images/newchicoStart1.png" style="width: 10px;height: 10px;" />`
                }
              }

              liTag += `
                      </div>
                      <div style="font-size: 12px; color: #999999; margin-top: 8px; ">客户评星</div>
                    </div>
                    <div style="display: flex;flex-direction: column;align-items: center; margin-left: 25px;">
                      <div class="fuwuzhong-item-content">
                        <div class="text-number" style="color: #474747;width: 92px;">${item.content ? item.content : '暂无评价内容'}</div>
                      </div>
                      <div style="font-size: 12px; color: #999999; margin-top: 8px; ">客户评价</div>
                    </div>
                  </div>
                </a>
                `
              var liTagold = `<li class="clearfix historyDeal">
                <a target="_blank" href="/project-center-details/${item.orderNo
                }">
                <div class="projectTop">
                <div class="nameType"  style="width:254px;">
                <h6 title="${item.orderName ? item.orderName : '-'}" style="${item.orderKind === 2
                  ? 'margin-right:5px;'
                  : 'max-width:224px;margin-right:30px'
                }">${item.orderName ? item.orderName : '-'}</h6>
                                      ${item.orderKind === 2
                  ? `<span class="imgTypeBox" style="margin-right:30px;"><img class="imgType" src='/images/lodding.png' data-src="/images/moreProduct.png" /></span>`
                  : ''
                }
                </div>
                      <p class="enterpriseName">发布者:${item.enterpriseName ? item.enterpriseName : '--'
                }</p>
                <div class="projectAddress">
                <span class="posImg"></span>
                    <p title="${item.dealAddress ? item.dealAddress : '--'}">${item.dealAddress ? item.dealAddress : '--'
                }</p>
                    </div>
                </div>
                <div class="projectDown ">
                    <dl>
                        <dd>
                            <p  class="big orange">${item.price
                  ? '<span>￥</span>' +
                  formatNumber(item.price, 2)
                  : '<span>￥</span>0.00'
                }</p>
                    <h6>完成价格</h6>
                </dd>
                <dd>
                    <p class="big">${item.salePrice
                  ? '<span>￥</span>' + formatNumber(item.salePrice, 2)
                  : '<span>￥</span>0.00'
                }</p>
                    <h6>低于市场价格</h6>
                </dd>
                <dd>
                    <p>${item.completeDays ? item.completeDays + '天' : '1天'
                }</p>
                    <h6>承诺时长</h6>
                </dd>
                <dd>
                    <p>${todate3(item.serverEndTime - item.serverStartTime)}</p>
                    <h6>完成用时</h6>
                </dd>
                <dd>
                    <div class="pingxing">`
              for (var i = 0; i < 5; i++) {
                if (item.score / 2 >= i + 1) {
                  liTagold += `<i><img src='/images/lodding.png' data-src="/images/newchicoStart.png" alt=""></i>`
                } else if (item.score % 2 != 0 && item.score / 2 > i) {
                  liTagold += `<i><img src="/images/newchicoStart2.png" alt=""></i>`
                } else {
                  liTagold += `<i><img src="/images/newchicoStart1.png" alt=""></i>`
                }
              }
              liTagold += `</div>
                    <h6>客户评星</h6>
                </dd>
                <dd>
                    <p class="comment oneLine">${item.content ? item.content : '暂无评价内容'
                }</p>
                <div class="shuliangTitle">
                    <img src='/images/lodding.png' data-src="/images/project/sanjiao.png" alt="">
                    <div class="shuliangTitleText">${item.content ? item.content : '暂无评价内容'
                }</div>
                              </div>
                              <h6>客户评价</h6>
                          </dd>
                      </dl>
                  </div>
                  </a>
              </li>`

              $('.project-center-view2-left-main ul').append(liTagold)
              $('.project-center-new-botom-right-content').append(liTag)
            } else if (projectType == 4) {
              let time = new Date().getTime()
              item.acceptanceTime = item.acceptanceTime
                ? item.acceptanceTime
                : time

              var refundOrderStatusText = ''
              switch (item.refundOrderStatus) {
                case '1':
                  refundOrderStatusText = '订单关闭'
                  break
                case '2':
                  refundOrderStatusText = '待提交'
                  break
                case '10':
                  refundOrderStatusText = '审批中'
                  break
                case '20':
                  refundOrderStatusText = '招标中'
                  break
                case '30':
                  refundOrderStatusText = '待付全款'
                  break
                case '40':
                  refundOrderStatusText = '服务者待应标'
                  break
                case '50':
                  refundOrderStatusText = '服务中'
                  break
                case '60':
                  refundOrderStatusText = '逾期中'
                  break
                case '70':
                  refundOrderStatusText = '退款中'
                  break
                case '80':
                  refundOrderStatusText = '待验收'
                  break
                case '96':
                  refundOrderStatusText = '订单取消'
                  break
                case '97':
                  refundOrderStatusText = '订单全部退单'
                  break
                case '98':
                  refundOrderStatusText = '订单部分完成'
                  break
                case '99':
                  refundOrderStatusText = '订单全部完成'
                  break
                default:
                  refundOrderStatusText = '未知'
                  break
              }

              var liTag = `
               <a target="_blank" href="/project-center-details/${item.orderNo}" class="fuwuzhong-item">
            <div class="fuwuzhong-item-top">
              <div class="tag-1">
                工商财税</div>
              <div class="tag-2">
                多</div>
              <div class="project-name">${item.orderName ? item.orderName : '-'}</div>
              <div class="fuwuzhong-item-top-content-margin">发布者:${item.enterpriseName ? item.enterpriseName : '--'}</div>
              <span class="localtion-icon"></span>
              <div class="fuwuzhong-item-top-content">${item.dealAddress ? item.dealAddress : '--'}</div>
            </div>
            <div class="fuwuzhong-item-divider"></div>
            <div class="fuwuzhong-item-content">
              <div style="display: flex;flex-direction: column;flex: 1;">
                <div class="fuwuzhong-item-content">
                  <div class="text-number">${item.userSettleAccount ? formatNumber(item.userSettleAccount, 2) : '0.00'}元</div>
                </div>
                <div style="font-size: 12px; color: #999999; margin-top: 8px; ">退款金额</div>
              </div>
              <div style="display: flex;flex-direction: column;align-items: center; margin-left: 25px;">
                <div class="fuwuzhong-item-content">
                  <div class="text" style="color: #474747;">中止并退款</div>
                </div>
                <div style="font-size: 12px; color: #999999; margin-top: 8px; ">处理结果</div>
              </div>
              <div style="display: flex;flex-direction: column;align-items: center; margin-left: 80px;">
                <div class="fuwuzhong-item-content">
                  <div class="text-number" style="color: #474747;width:168px; font-size: 12px;">
                    ${item.refundReason ? item.refundReason : '无责取消订单'}</div>
                </div>
                <div style="font-size: 12px; color: #999999; margin-top: 8px; ">中止原因</div>
              </div>
              <div style="display: flex;flex-direction: column; margin-left: 60px;">
                <div class="fuwuzhong-item-content">
                  <div class="text" style="color: #474747;">${getStartTime(item.refundApplyTime, 'YYYY/MM/DD HH:mm:ss')}</div>
                </div>
                <div style="font-size: 12px; color: #999999; margin-top: 8px; ">审核时间</div>
              </div>
              <div style="display: flex;flex-direction: column; margin-left: 60px;">
                <div class="fuwuzhong-item-content">
                  <div class="text" style="color: #474747;">${getStartTime(item.refundEndTime, 'YYYY/MM/DD HH:mm:ss')}</div>
                </div>
                <div style="font-size: 12px; color: #999999; margin-top: 8px; ">中止时间</div>
              </div>
            </div>
          </a>
              `

              var liTagold = `<li class="clearfix historyDeal">
                <a target="_blank" href="/project-center-details/${item.orderNo
                }">
                <div class="projectTop">
                <div class="nameType"  style="width:254px;">
                <h6 title="${item.orderName ? item.orderName : '-'}" style="${item.orderKind === 2
                  ? 'margin-right:5px;'
                  : 'max-width:224px;margin-right:30px'
                }">${item.orderName ? item.orderName : '-'}</h6>
                                      ${item.orderKind === 2
                  ? `<span class="imgTypeBox" style="margin-right:30px;"><img class="imgType" src='/images/lodding.png' data-src="/images/moreProduct.png" /></span>`
                  : ''
                }
                </div>
                      <p class="enterpriseName">发布者:${item.enterpriseName ? item.enterpriseName : '--'
                }</p>
                <div class="projectAddress">
                <span class="posImg"></span>
                    <p title="${item.dealAddress ? item.dealAddress : '--'}">${item.dealAddress ? item.dealAddress : '--'
                }</p>
                    </div>
                </div>
                <div class="projectDown ">
                    <dl>
                        <dd>
                            <p  class="big orange">${item.userSettleAccount
                  ? '<span>￥</span>' +
                  formatNumber(item.userSettleAccount, 2)
                  : '<span>￥</span>0.00'
                }</p>
                    <h6>退款金额</h6>
                </dd>
                <dd style="width:160px">
                    <p>${getStartTime(
                  item.refundApplyTime,
                  'YYYY/MM/DD HH:mm:ss'
                )}</p>
                    <h6>申请时间</h6>
                </dd>
                <dd style="width:160px">
                    <p>${getStartTime(
                  item.refundEndTime,
                  'YYYY/MM/DD HH:mm:ss'
                )}</p>
                    <h6>中止时间</h6>
                </dd>
               
                <dd>
                    <p class="comment oneLine">${item.refundReason ? item.refundReason : '无责取消订单'
                }</p>
                <div class="shuliangTitle">
                    <img src='/images/lodding.png' data-src="/images/project/sanjiao.png" alt="">
                    <div class="shuliangTitleText">${item.refundReason ? item.refundReason : '无责取消订单'
                }</div>
                              </div>
                              <h6>中止原因</h6>
                </dd>
                <dd>
                  <p>中止并退款</p>
                  <h6>处理结果</h6>
                </dd>
              </dl>
            </div>
          </a>
        </li>`

              $('.project-center-view2-left-main ul').append(liTagold)
              $('.project-center-new-botom-right-content').append(liTag)
            }
          })

          $('.comment')
            .parent('dd')
            .hover(
              function () {
                $(this)
                  .find('.shuliangTitle')
                  .show()
              },
              function () {
                $(this)
                  .find('.shuliangTitle')
                  .hide()
              }
            )
        }
      },
      'json'
    )
  }

  /**
   * 招标倒计时
   * @param {*} timestamp unix时间戳
   */

  var timers = {}

  function getCountDown(timestamp, index) {
    timers['timer' + index] = setInterval(function () {
      var nowTime = new Date(+timestamp)
      var endTime = new Date()

      var t = endTime.getTime() - nowTime.getTime()
      var dataDay, dataHour, dataMin, dataSec
      if (t < 0) {
        clearInterval('timer' + index)
        dataDay = dataHour = dataMin = dataSec = ['0', '0']
      } else {
        var d = +Math.floor(t / 1000 / 60 / 60 / 24)
        var hour = Math.floor((t / 1000 / 60 / 60) % 24)
        var hour2 = d * 24 + hour
        var min = Math.floor((t / 1000 / 60) % 60)
        var sec = Math.floor((t / 1000) % 60)
        var day = d < 10 ? '0' + d : d + ''
        hour < 10 ? (hour = '0' + hour) : (hour = hour + '')
        hour2 < 10 ? (hour2 = '0' + hour2) : (hour2 = hour2 + '')
        min < 10 ? (min = '0' + min) : (min = min + '')
        sec < 10 ? (sec = '0' + sec) : (sec = sec + '')
        dataDay = day.split('')
        dataHour = hour.split('')
        dataMin = min.split('')
        dataSec = sec.split('')
      }
      var dataValA = `<i>${hour}</i>时<i>${min}</i>分<i>${sec}</i>秒`
      if (hour == undefined || min == undefined || sec == undefined) {
        $('.dataValA' + index).html('即将结束')
        $('.dataValHour' + index).html('00')
        $('.dataValMin' + index).html('00')
        $('.dataValSec' + index).html('00')
      } else {
        $('.dataValA' + index).html(dataValA)
        $('.dataValA').css('pointer-events', 'none')
        $('.dataValHour' + index).html(hour)
        $('.dataValMin' + index).html(min)
        $('.dataValSec' + index).html(sec)

      }
    }, 1000)
  }


  // 获取banner轮播列表 green
  function getBannerList() {
    $.post('/getBannerList', function (result) {
      var result = JSON.parse(result)
      if (result.code == 0) {
        var BannerList = result.content
        var BannerHtml = ''
        $.each(BannerList, function (k, v) {
          BannerHtml +=
            '<div class="swiper-slide green">' +
            '<a href=' +
            BannerList[k].jumpUrl +
            ' target="_blank">' +
            '<img src="/images/lodding.png" data-src=' +
            BannerList[k].url +
            ' alt="">' +
            '</a>' +
            '</div>'
        })
        $('.banner-list').append(BannerHtml)
      } else {
        // 错误
      }

      var mySwiper = new Swiper('.swiper-container2', {
        autoplay: {
          disableOnInteraction: false,
          delay: 2500
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      })

      /*鼠标移入停止轮播，鼠标离开 继续轮播*/
      // mySwiper.el.onmouseover = function () {
      //   //鼠标放上暂停轮播
      //   mySwiper.autoplay.stop()
      // }
      // mySwiper.el.onmouseleave = function () {
      //   mySwiper.autoplay.start()
      // }
    })
  }
  // 获取优秀服务者
  function getTopServer() {
    var data = {
      data: JSON.stringify({
        num: 32
      })
    }
    $('.project-center-view2-right .loader').fadeIn()
    $.post('/getTopServer', data, function (result) {
      var result = JSON.parse(result)
      if (result.code == 0) {
        var data = result.content
        var html1 = ''
        var html = ""
        var objarr = {}
        $('.project-center-view2-right .loader').fadeOut()
        $.each(data, function (index, value) {
          var i = Math.floor(index / 2)
          if (objarr[i]) {
            objarr[i].push(value)
          } else {
            objarr[i] = []
            objarr[i].push(value)
          }
        })
        $.each(objarr, function (key, val) {
          try {
            var src = null,
              src1 = null,
              altVal = null,
              altVal2 = null
            if (val[0].headUrl && val[0].showPic == 1) {
              src = val[0].headUrl
              altVal = ''
            } else {
              altVal = ' title="该服务者选择不展示头像"'
              if (val[0].sex == 0) {
                src = '/images/topServerHeader0.png'
              } else {
                src = '/images/topServerHeader1.png'
              }
            }

            if (val[1].headUrl && val[1].showPic == 1) {
              src1 = val[1].headUrl
              altVal2 = ''
            } else {
              altVal2 = 'title="该服务者选择不展示头像"'
              if (val[1].sex == 0) {
                src1 = '/images/topServerHeader0.png'
              } else {
                src1 = '/images/topServerHeader1.png'
              }
            }
            if (0 <= key && key < 16) {
              var item = `
              <div class="good-servers-content-item" data-serverNo=${val[0].serverNo}
              href='/users/service-details?serverNo=${val[0].serverNo}'>
                <img src='${src}' style="width: 65px;height: 65px; background-color: red;" />
                <div class="good-servers-content-right">
                  <div class="good-servers-content-right-top">
                    <div class="good-servers-content-right-top-title">${val[0].userName}</div>
                    <div class="good-servers-content-right-top-right">
                      <p>服务单数：</p>
                      <p style="color: #FF9852;">${val[0].serverCount}</p>
                      <p>单</p>
                    </div>
                  </div>
                  <div class="good-servers-content-right-center">
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <p style="margin-left: 5px;">8.0</p>
                  </div>
                  <div class="good-servers-content-right-bottom">${val[0].introduce}</div>
                </div>
              </div>
              <div class="good-servers-content-item" data-serverNo=${val[1].serverNo}
              href='/users/service-details?serverNo=${val[1].serverNo}'> 
                <img src='${src1}' style="width: 65px;height: 65px; background-color: red;" />
                <div class="good-servers-content-right">
                  <div class="good-servers-content-right-top">
                    <div class="good-servers-content-right-top-title">${val[1].userName}</div>
                    <div class="good-servers-content-right-top-right">
                      <p>服务单数：</p>
                      <p style="color: #FF9852;">${val[1].serverCount}</p>
                      <p>单</p>
                    </div>
                  </div>
                  <div class="good-servers-content-right-center">
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <img src="/images/newchicoStart.png" style="width: 10px;height: 10px;" />
                    <p style="margin-left: 5px;">8.0</p>
                  </div>
                  <div class="good-servers-content-right-bottom">${val[1].introduce}</div>
                </div>
              </div>
              `
              var item1 =
                "<div class='swiper-slide swiper-slide-service pull-left clearfix'><a href='/users/service-details?serverNo=" +
                val[0].serverNo +
                "&orderNo=;' target='_blank'><img class='pull-left topServerHeader' src=" +
                src +
                ' ' +
                altVal +
                "><div class='pull-right txt'><div class='clearfix txt-view1'><h3 class='pull-left'>" +
                val[0].userName +
                "</h3><div class='pull-right'><img class='pull-left' src='/images/iconjin1130.png' alt=''><p class='pull-left'>服务单数<span>" +
                val[0].serverCount +
                '</span>单</p></div></div><p>TA说：' +
                val[0].introduce +
                "</p></div></a><a target='_blank' href='/users/service-details?serverNo=" +
                val[1].serverNo +
                "&orderNo=;' target='_blank'><img class='pull-left topServerHeader' src=" +
                src1 +
                ' ' +
                altVal2 +
                "><div class='pull-right txt'><div class='clearfix txt-view1'><h3 class='pull-left'>" +
                val[1].userName +
                "</h3><div class='pull-right'><img class='pull-left' src='/images/iconjin1130.png' alt=''><p class='pull-left'>服务单数<span>" +
                val[1].serverCount +
                '</span>单</p></div></div><p>TA说：' +
                val[1].introduce +
                '</p></div></a></div>'
              html1 += item1
              html += item
            }
          } catch (error) { }
        })

        $('#topServerList').html(html1)
        $('.good-servers-content').html(html)

        var mySwiper = new Swiper('#swipe-service', {
          direction: 'vertical',
          slidesPerView: 9,
          autoplay: {
            disableOnInteraction: false
          },
          loop: true
        })

        /*鼠标移入停止轮播，鼠标离开 继续轮播*/
        // mySwiper.el.onmouseover = function () {
        //   //鼠标放上暂停轮播
        //   mySwiper.autoplay.stop()
        // }
        // mySwiper.el.onmouseleave = function () {
        //   mySwiper.autoplay.start()
        // }
      }
    })
  }
  $('.good-servers-content').on('click', '.good-servers-content-item', function () {
    window.open($(this).attr('href'))
  })
  $('#imgFWZ').click(function () {
    window.open('/recruit')
  })

  // 获取交易大厅列表
  function getTradingList(type, page, pageSize) {
    function renderingData(response) {
      response = JSON.parse(response)
      var result = response.content
      var data = result.data
      var htmlold = ''
      var html = ''
      $.each(data, function (index, value) {
        value.busImg = value.busImg.replace(/^http:/, 'https:')
        let { businessDetails } = value

        let busDetailIndex = ''
        if (businessDetails.length > 0) {
          businessDetails.forEach((element, index) => {
            if (element.busDetailKey === '业务描述') {
              busDetailIndex = index
            } else busDetailIndex = 0
          });
        }

        var item = `
        <a target='_blank' href='/trading?type=${type}' class="trading-market-new-right-item">
          <div class="trading-market-new-right-item-top">
            <img src="/images/lodding.png" data-src=${value.busImg} alt='' />
          </div>
          <div class="trading-market-new-right-item-title">
            <div class="warp-line">${value.busName}</div>
          </div>
          <div class="trading-market-new-right-item-bottom">
            <div class="warp-line">${value.businessDetails[busDetailIndex || 0].busDetailKey}：${value.businessDetails[busDetailIndex || 0].busDetailValue}</div>
          </div>
        </a>
        `
        html += item

        var itemold =
          '<li><img src="/images/lodding.png" data-src=' +
          value.busImg +
          " alt=''><h4>" +
          value.busName +
          '</h4><p>' +
          value.businessDetails[0].busDetailKey +
          '：<span>' +
          value.businessDetails[0].busDetailValue +
          '</span></p><p>' +
          value.businessDetails[1].busDetailKey +
          '：<span>' +
          value.businessDetails[1].busDetailValue +
          "</span></p><div class='liHover'><a  class='cumService-btn' href='javascript:void(0)'><i></i><span class='pull-left'>立即咨询</span></a></div><div class='liHover2'><a target='_blank' href='/trading?type=" +
          type +
          "'><img class='pull-left' src='/images/lodding.png' data-src='/images/moreicon1128.png' alt=''><span class='pull-left'>更多交易</span></a></div></li>"
        htmlold += itemold
      })
      $('#tradingList').html(htmlold)
      $('.trading-market-new-right-top').html(html)
      cumServiceFn()
      Limg()
    }

    if (sessionStorage.getItem(`businessList${type}`)) {
      renderingData(sessionStorage.getItem(`businessList${type}`))
    } else {
      var data = {
        busType: type,
        pageNo: page,
        pageSize: pageSize
      }
      $.ajax({
        type: 'POST',
        url: '/businessList',
        data: data,
        dataType: 'json',
        beforeSend: function () {
          $('.trading-market-view2 .loader').fadeIn()
        },
        success: function (response) {
          $('.trading-market-view2 .loader').fadeOut()
          if (response.code == 0) {
            response = JSON.stringify(response)
            sessionStorage.setItem(`businessList${type}`, response)
            renderingData(response)
          } else {
            toast(response.msg)
          }
        }
      })
    }
  }
  $('#marketTypeList').on('click', 'div', function () {
    var type = $(this).attr('data-marketid')
    $(this)
      .addClass('active')
      .siblings()
      .removeClass('active')
    getTradingList(type, tradingPage, tradingSize)
  })
  // 交易市场类别切换
  $('#tradingTypeList').on('click', 'li', function () {
    var type = $(this).attr('data-id')
    // 添加类型标签切换样式
    $(this)
      .addClass('active')
      .siblings()
      .removeClass('active')
    getTradingList(type, tradingPage, tradingSize)
  })
  /**
   * 首页招标中心
   * 默认加载正在招标
   *
   */
  vmProject.getProjectList(3)
  //指数信息

  //数字自增到某一值动画参数（目标元素,自定义配置）
  function NumAutoPlusAnimation(targetEle, options) {
    /*可以自己改造下传入的参数，按照自己的需求和喜好封装该函数*/
    //不传配置就把它绑定在相应html元素的data-xxxx属性上吧
    options = options || {}

    var $this = document.getElementById(targetEle),
      time = options.time || $this.data('time'), //总时间--毫秒为单位
      finalNum = options.num || $this.data('value'), //要显示的真实数值
      regulator = options.regulator || 100, //调速器，改变regulator的数值可以调节数字改变的速度
      step = finalNum / (time / regulator),
      /*每30ms增加的数值--*/
      count = 0, //计数器
      initial = 0

    var timer = setInterval(function () {
      count = count + step

      if (count >= finalNum) {
        clearInterval(timer)
        count = finalNum
      }
      //t未发生改变的话就直接返回
      //避免调用text函数，提高DOM性能
      var t = Math.floor(count)
      if (t == initial) return
      initial = t
      $this.innerHTML = initial
    }, 30)
  }

  vmProject.getTrade = function () {
    $.get(
      '/getTradeAmountInfo',
      function (result) {
        var code = result.code
        if (code && code === '0') {
          var data = result.content

          if (data.provider == '0') {
            $('#time3').text('0')
          } else {
            NumAutoPlusAnimation('time3', {
              time: 1500,
              num: data.provider,
              regulator: 50
            })
          }
        } else {
          $('.product-num .span1').text(208)
          $('.product-num .span2').text(152)
          $('.product-num .span3').text(168)
        }
      },
      'json'
    )
  }
  vmProject.getTrade()
  // 交易中心
  let mektArr = []
  function merktBox(val, content) {
    switch (val) {
      case 1:
        mektArr.push({
          title: '店铺转让',
          sortId: 1,
          content
        })
        break;
      case 2:
        mektArr.push({
          title: '商标转让',
          sortId: 2,
          content
        })
        break;
      case 3:
        mektArr.push({
          title: '建筑资质',
          sortId: 3,
          content
        })
        break;
      case 4:
        mektArr.push({
          title: '资质转让',
          sortId: 4,
          content
        })
        break;
      case 5:
        mektArr.push({
          title: '公司转让',
          sortId: 5,
          content
        })
        break;
    }
  }

  function eachMektArr(mektArr) {
    let html = ''

    $.each(mektArr, (index, item) => {
      let htmlSon = ''
      $.each(item.content, (indexA, itemA) => {
        htmlSon += `<div class="itemsBox" data-id="${item.sortId}">
                  <div class="imgBox">
                    <img src="${itemA.busLogo}" alt="">
                  </div>
                  <div class="itemsBoxTit">${itemA.busTitle}</div>
                </div>`
      })
      html += `<div class="rightItem fl">
                <div class="rightItemTit">
                    ${item.title}
                    <div class="rightItemTitIcon" data-id="${item.sortId}">
                      <img src="/images/n1.png" alt="">
                    </div>
                </div>
                <div class="rightArrBox">
                ${htmlSon}
                </div>
              </div>`
    })

    $('#rightBoxMain').html(html)
  }
  // 交易中心前4 
  function tabList(val) {
    $.ajax({
      type: 'post',
      url: '/businessTop',
      data: { busType: val },
      dataType: 'json',
      success: function (res) {
        if (res.code === '0') {
          merktBox(val, res.content)
          mektArr.sort((a, b) => a.sortId - b.sortId);
          eachMektArr(mektArr)
        } else {
          toast(res.msg)
        }
      }
    })
  }
  tabList(1)
  tabList(2)
  tabList(3)
  tabList(4)
  tabList(5)
  $('#rightBoxMain').on('click', '.rightItemTitIcon,.itemsBox', function () {
    window.open(`/trading?type=${$(this).attr('data-id')}`)
  })
})
busDialog()
new Vue({
  el: '#app',
  data: function () {
    return {
      mapinfo: {
        lng: 116.35377023454834,
        lat: 39.937281200576166
      },
      serverTotal: 0,
      mapserverisshow: false,
      cityName: '',
    }
  },
  mounted() {
    // this.oldMap()
    this.newMap()
  },
  created() { },
  methods: {
    newMap() {
      let _this = this
      $.ajax({
        type: 'GET',
        url: 'http://my.ip.cn/json/',
        dataType: 'json',
        success: function (res) {
          // _this.cityName = res.data.city
          $('#switch_c').html(res.data.city)
        }
      })
    },
    oldMap() {
      const _this = this
      try {
        let gaode = _this.gaodeMap()
        gaode
          .then(function (r) {
            _this.map(r)
          })
          .catch(function (e) {
            _this.map('error')
          })
      } catch (error) {
        _this.gaodeMap2(callback)

        function callback(point) {
          _this.map(point)
        }
      }
    },

    map: function (data) {
      // 百度地图API功能

      var _this = this
      var map = new BMap.Map('allmap') // 创建Map实例
      var point = new BMap.Point(_this.mapinfo.lng, _this.mapinfo.lat)
      map.centerAndZoom(point, 12)
      map.enableScrollWheelZoom(true)
      map.disableDragging() //禁止拖拽
      map.disableScrollWheelZoom() //禁止缩放

      map.addControl(new BMap.NavigationControl())
      map.addControl(new BMap.ScaleControl())
      map.addControl(new BMap.OverviewMapControl())
      map.addControl(new BMap.MapTypeControl())

      if (data == 'error') {
        console.log('error百度地图')
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              // alert('您的位置：' + r.point.lng + ',' + r.point.lat) https://package.fuqim.com/images/gif/position-logo.gif
              var myIcon = new BMap.Icon(
                '',
                new BMap.Size(140, 140)
              )
              myIcon.setImageSize(new BMap.Size(140, 140))
              var point2 = new BMap.Point(r.point.lng, r.point.lat)

              // 创建标注对象并添加到地图
              var marker = new BMap.Marker(point2, {
                icon: myIcon
              })
              map.addOverlay(marker)
              marker.disableMassClear() // 禁止清除
              marker.enableDragging() // 可以拖拽

              marker.addEventListener('dragend', function () {
                var p = marker.getPosition()
                map.clearOverlays() // 清除图层覆盖物
                _this.mapinfo.lat = p.lat
                _this.mapinfo.lng = p.lng
                _this.getServerMap(map)
              })
              map.addOverlay(marker)
              map.panTo(point2)

              _this.mapinfo.lng = r.point.lng
              _this.mapinfo.lat = r.point.lat

              _this.getServerMap(map)
            } else {
              alert('failed' + this.getStatus())
              // <img src='/images/lodding.png' data-src='https://package.fuqim.com/images/indexMap.png'>
              $('#allmap').html(
                ""
              )
            }
          },
          {
            enableHighAccuracy: true
          }
        )
      } else if (data.lng) {
        console.log('data.lng百度地图')
        // https://package.fuqim.com/images/gif/position-logo.gif
        var myIcon = new BMap.Icon(
          '',
          new BMap.Size(140, 140)
        )
        myIcon.setImageSize(new BMap.Size(140, 140))
        var point2 = new BMap.Point(data.lng, data.lat)

        // 创建标注对象并添加到地图
        var marker = new BMap.Marker(point2, {
          icon: myIcon
        })
        map.addOverlay(marker)
        marker.disableMassClear() // 禁止清除
        marker.enableDragging() // 可以拖拽

        marker.addEventListener('dragend', function () {
          var p = marker.getPosition()
          map.clearOverlays() // 清除图层覆盖物
          _this.mapinfo.lat = p.lat
          _this.mapinfo.lng = p.lng
          _this.getServerMap(map)
        })
        map.addOverlay(marker)
        map.panTo(point2)

        _this.mapinfo.lng = data.lng
        _this.mapinfo.lat = data.lat

        _this.getServerMap(map)
      }

      map.onclick = function () {
        if (_this.mapserverisshow) {
          _this.removeMapserver()
          _this.mapserverisshow = false
        }
      }
    },
    getServerMap: function (map) {
      var _this = this
      var queryServerMap = {
        lat: _this.mapinfo.lat,
        lon: _this.mapinfo.lng,
        secondCategory: ''
      }
      var queryServerMap = JSON.stringify(queryServerMap)
      $.post(
        '/mapServerList',
        {
          queryServerMap: queryServerMap
        },
        function (result) {
          var result = JSON.parse(result)
          if (result.code === '0') {
            _this.serverTotal = result.content.total
            result.content.queryServerMapResults.map(item => {
              item.isShow = false
              _this.getAttr(map, item)
            })
          } else {
            toast(result.msg)
          }
        }
      )
    },
    getAttr: function (map, item) {
      let _this = this
      var pt = new BMap.Point(item.coordinate.lon, item.coordinate.lat)
      var myIcon = new BMap.Icon(
        '/images/serverIconAddr.png',
        new BMap.Size(16, 16),
        {}
      )
      myIcon.setImageSize(new BMap.Size(16, 16))
      var marker3 = new BMap.Marker(pt, {
        icon: myIcon
      }) // 创建标注
      map.addOverlay(marker3)
      marker3.onclick = function (e) {
        e.domEvent.stopPropagation()
        _this.getlabel(map, marker3, pt, item)
      }
    },
    removeMapserver: function () {
      let mapserver = document.getElementsByClassName('mapserver')
      for (let j = 0; j < mapserver.length; j++) {
        mapserver[j].parentNode.style.display = 'none'
      }
    },
    getlabel: function (map, marker2, pt, item) {
      this.removeMapserver()

      if (item.isShow) {
        item.isShow = false
        return true
      }
      var opts = {
        position: pt, // 指定文本标注所在的地理位置
        offset: new BMap.Size(30, -30) // 设置文本偏移量
      }
      let starnum = (item.serviceStar || 0) / 2
      let starstr = '<div class="star-cell"><div class="star-icons">'
      for (let i = 1; i < 6; i++) {
        if (starnum === i) {
          starstr += '<i class="icon icon-light "></i>'
        } else if (starnum > i) {
          starstr += '<i class="icon icon-light"></i>'
        } else if (starnum < i && starnum > i - 1) {
          starstr += '<i class="icon icon-half"></i>'
        } else {
          starstr += '<i class="icon"></i>'
        }
      }
      starstr += '</div></div>'
      let str = `<div class='mapserver'>
                  <div class='mapserver_cell'>
                    <label>服务者：</label>${item.serverName}
                    <label>当前服务：</label>${item.nowServerCount}单
                  </div>
                  <div class='mapserver_cell'>
                    <label>已服务数：</label>${item.serverCount}单
                  </div>
                  <div class='xian flex'></div>
                  <div class='mapserver_cell flex xingxing'>
                    <label>服务评价：</label> ${starstr}
                  </div>
                </div>`
      var label = new BMap.Label(str, opts) // 创建文本标注对象
      label.setStyle({
        zIndex: item.coordinate.lon,
        borderRadius: '5px',
        padding: '15px',
        height: '90px',
        border: '1px solid #fff',
        background: '#fff',
        transform: 'translate(-50%, -50%)',
        marginLeft: '-20px '
      })
      map.addOverlay(label)
      item.isShow = true

      this.mapserverisshow = true
      return false
    },
    deletePoint: function (map) {
      var allOverlay = map.getOverlays()
      for (var i = 0; i < allOverlay.length - 1; i++) {
        map.removeOverlay(allOverlay[i])
        return false
      }
    },
    gaodeMap: function () {
      return new Promise(function (resolve, reject) {
        var map = new AMap.Map('container1', {
          resizeEnable: true
        })
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', //定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
          })
          map.addControl(geolocation)
          geolocation.getCurrentPosition(function (status, result) {
            if (status == 'complete') {
              function bd_encrypt(gg_lng, gg_lat) {
                var X_PI = (Math.PI * 3000.0) / 180.0
                var x = gg_lng,
                  y = gg_lat
                var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
                var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
                var bd_lng = z * Math.cos(theta) + 0.0065
                var bd_lat = z * Math.sin(theta) + 0.006
                return {
                  lat: bd_lat,
                  lng: bd_lng
                }
              }
              let point = bd_encrypt(result.position.lng, result.position.lat)
              resolve(point)
            } else {
              reject(false)
            }
          })
        })
      })
    },

    gaodeMap2: function (callback) {
      var map = new AMap.Map('container1', {
        resizeEnable: true
      })
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
          buttonPosition: 'RB', //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
        })
        map.addControl(geolocation)
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            function bd_encrypt(gg_lng, gg_lat) {
              var X_PI = (Math.PI * 3000.0) / 180.0
              var x = gg_lng,
                y = gg_lat
              var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
              var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
              var bd_lng = z * Math.cos(theta) + 0.0065
              var bd_lat = z * Math.sin(theta) + 0.006
              return {
                lat: bd_lat,
                lng: bd_lng
              }
            }

            let point = bd_encrypt(result.position.lng, result.position.lat)
            callback(point)
          }
        })
      })
    }
  }
})

$(".business-content-title-left").click(() => {

  $('.business-content-container-left').css("display", "flex")
  $('.business-content-container-right').css("display", "none")

  $(".business-content-title-right").css("height", "50px")
  $(".business-content-title-right").css("background", "#E6E8F8")
  $(".business-content-title-right").css("z-index", "0")
  $(".business-content-title-right p").css("color", "#333")

  $(".business-content-title-left").css("height", "60px")
  $(".business-content-title-left").css("background", "url('../images/title-left.png') no-repeat bottom right")
  $(".business-content-title-left").css("z-index", "10")
  $(".business-content-title-left p").css("color", "#FF761A")
})

$(".business-content-title-right").click(() => {
  $('.business-content-container-left').css("display", "none")
  $('.business-content-container-right').css("display", "flex")

  $(".business-content-title-left").css("height", "50px")
  $(".business-content-title-left").css("background", "#E6E8F8")
  $(".business-content-title-left").css("z-index", "0")
  $(".business-content-title-left p").css("color", "#333")

  $(".business-content-title-right").css("height", "60px")
  $(".business-content-title-right").css("background", "url('../images/title-right.png') no-repeat bottom left")
  $(".business-content-title-right").css("z-index", "10")
  $(".business-content-title-right p").css("color", "#FF761A")
})
